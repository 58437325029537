export type DownLoadPDF = {
  apiKey: string
  documentType: string
  documentUrl: string
  name: string
  pipeline: number
  test: boolean
}

export const usePdfRaptor = () => {
  const config = useRuntimeConfig()
  const pdfInformations = ref<DownLoadPDF>({
    apiKey: config.public.docRaptorPdf,
    documentType: 'pdf',
    documentUrl: '',
    name: '',
    pipeline: 9.2,
    test: config.public.environment !== 'production',
  })

  const setPdfInformations = (
    url: string,
    privateToken: string,
    name: string,
  ) => {
    let documentUrl = url
    const finalName = name.replace(' ', '-')

    if (privateToken) {
      documentUrl = `${documentUrl}?private-token=${privateToken}`
    }

    // Define name + documentUrl
    pdfInformations.value.name = finalName
    pdfInformations.value.documentUrl = documentUrl
  }

  return {
    pdfInformations,
    setPdfInformations,
  }
}
